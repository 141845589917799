import React from 'react'
import { Animate, Card, Table, TitleRow, useNavigate, useView } from 'components/lib'
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { states, timeZones } from 'helper/states';

function Campaigns() {
  const view = useView()
  const navigate = useNavigate()

  const statuses = [
    { value: 'active', color: 'green' },
    { value: 'deleted', color: 'red' },
    { value: 'past due', color: 'orange' },
    { value: 'delinquent', color: 'purple' },
    { value: 'suspended', color: 'red' },
    { value: 'billing issue', color: 'yellow' },
    { value: 'cancelled', color: 'gray' },
  ]

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['campaigns'],
    queryFn: () => axios.get('/api/campaigns')
  })

  const editCampaign = (data, callback) => {
    console.log(data)
    view.modal.show({
      title: `Update Campaign ${data.campaign_name}`,
      text: 'For complicated updates, please login into the user account and edit the campaign there.',
      form: {
        name: {
          type: 'text',
          label: 'Campaign Name',
          value: data.campaign_name,
          containerClass: 'col-span-2'
        },
        business_name: {
          type: 'text',
          label: 'Business Name',
          value: data.business_name,
          containerClass: 'col-span-2'
        },
        callforwarding_number: {
          type: 'text',
          label: 'FWD Number',
          value: data.callforwarding_number
        },
        business_phonenumber: {
          type: 'text',
          label: 'Business Number',
          value: data.business_phonenumber
        },
        status: {
          label: 'Status',
          type: 'select',
          default: data.status,
          options: statuses.map(s => ({ value: s.value, label: s.value }))
        },
        timezone: {
          label: 'Timezone',
          type: 'select',
          default: data.timezone,
          options: timeZones
        },
        street_address: {
          type: 'text',
          label: 'Street Address',
          value: data.street_address,
        },
        state: {
          type: 'select',
          label: 'State',
          default: data.state,
          options: states
        },
        city: {
          type: 'text',
          label: 'City',
          value: data.city,

        },
        zip: {
          type: 'text',
          label: 'Zip',
          value: data.zip
        },
      },
      inputsClassName: 'grid sm:grid-cols-2 gap-2',
      buttonText: 'Save',
      url: `/api/campaigns/${data.id}`,
      method: 'PUT'
    }, () => {
      view.notification.show(`Campaign ${data.campaign_name} was updated`, 'success', true);
      refetch()
    });
  }

  const deleteCampaign = (data) => {
    view.modal.show({
      title: `Delete Campaign ${data.name}`,
      form: {},
      buttonText: 'Yes, delete',
      text: `Are you sure you want to delete "${data.campaign_name}" campaign?`,
      url: `/api/campaigns/${data.id}`,
      method: 'DELETE',
      destructive: true,
    }, () => {
      view.notification.show(`Campaign ${data.campaign_name} was deleted`, 'success', true);
      refetch()
    });
  }

  const viewCampaign = ({ id, user_id }) => {
    navigate(`/campaigns/${id}?user=${user_id}`)
  }

  // const voicemail = ({ id }) => {
  //   navigate(`/campaigns/${id}/vm_notifications`)
  // }

  // const callback = ({ id }) => {
  //   navigate(`/campaigns/${id}/cb_notifications`)
  // }

  const trunOnOffFeatures = ({ id, name }) => {
    navigate(`/campaigns/${id}/features?c=${name}`)
  }

  return (
    <Animate>
      <TitleRow title='All Campaigns'>
        <Card loading={isLoading}>
          <Table
            search
            data={data?.data?.campaigns}
            loading={isLoading}
            show={['campaign_name', 'business_address', 'FWD_number', 'plan', 'status', 'date_created']}
            badge={{
              col: 'status', color: 'green', condition: statuses
            }}
            actions={{
              custom: [
                { icon: 'eye', action: viewCampaign },
                // { icon: 'phone', action: voicemail, title: 'Voicemail' },
                // { icon: 'smartphone', action: callback, title: 'Callback' },
                { icon: 'toggle-right', title: 'Turn OFF/ON Features', action: trunOnOffFeatures, },
              ],
              edit: editCampaign,
              delete: deleteCampaign,
            }}
          />
        </Card>
      </TitleRow>
    </Animate>
  )
}

export default Campaigns