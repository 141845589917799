import React, { useEffect, useState } from 'react'
import { useAPI, PlanOption, Form, Loader, useView } from 'components/lib'
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Nav from './Nav'
import './planOptions.scss'


function PlanOptions() {
    const { data, loading } = useAPI('/api/stripe/plans')

    const [plans, setPlans] = useState([])
    const view = useView()

    const mutation = useMutation({
        mutationKey: ['save_plan_options'],
        mutationFn: () => axios.put('/api/stripe/plans', plans)
    })

    useEffect(() => {
        if (data?.plans) {
            setPlans(data.plans)
        }
    }, [data])

    const plan = data?.plans?.[0]

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Nav />
            <div className="grid xl:grid-cols-2 gap-4">
                <div className='bg-white py-6 px-3'>
                    <h1 className='font-semibold text-black text-lg mb-4'>Plan Options</h1>
                    <PlanOption
                        plans={plans}
                        loading={loading}
                        setPlans={setPlans}
                        mutation={mutation}
                    />
                </div>
                <div className='bg-white py-6 px-3'>
                    <h1 className='font-semibold text-black text-lg mb-4'>Voicemail & Callbacks</h1>

                    <Form
                        inputs={{
                            vm_price: {
                                type: 'number',
                                label: 'Voicemail price',
                                value: plan?.vm_price
                            },
                            vm_y_price: {
                                type: 'number',
                                label: 'Voicemail yearly price',
                                value: plan?.vm_y_price
                            },
                            vm_price_id: {
                                type: 'text',
                                label: 'Voicemail price ID',
                                value: plan?.vm_price_id
                            },
                            vm_y_price_id: {
                                type: 'text',
                                label: 'Voicemail yearly price ID',
                                value: plan?.vm_y_price_id
                            },
                            vm_features: {
                                type: 'textarea',
                                label: 'Voicemail features (new line separated)',
                                value: plan?.vm_features,
                                rows: 5
                            },
                            cb_price: {
                                type: 'number',
                                label: 'Callbacks price',
                                value: plan?.cb_price
                            },
                            cb_y_price: {
                                type: 'number',
                                label: 'Callbacks yearly price',
                                value: plan?.cb_y_price
                            },
                            cb_price_id: {
                                type: 'text',
                                label: 'Callbacks price ID',
                                value: plan?.cb_price_id
                            },
                            cb_y_price_id: {
                                type: 'text',
                                label: 'Callbacks yearly price ID',
                                value: plan?.cb_y_price_id
                            },
                            cb_features: {
                                type: 'textarea',
                                label: 'Callbacks features (new line separated)',
                                value: plan?.cb_features,
                                rows: 5
                            },
                        }}
                        buttonText='Save'
                        url='/api/stripe/vm_cb_plan_options'
                        method='PUT'
                        callback={(res) => {
                            view.notification.show(res.data?.data?.message, 'success', true)
                        }}
                    />
                </div>
            </div>
        </>
    )

}

export default PlanOptions