//write all unites states in array
export const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FM', label: 'Federated States Of Micronesia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PW', label: 'Palau' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
]

//write me the options time zines in array
export const timeZones = [
    { value: 'America/New_York', label: 'Eastern Time (ET)' },
    { value: 'America/Chicago', label: 'Central Time (CT)' },
    { value: 'America/Denver', label: 'Mountain Time (MT)' },
    { value: 'America/Phoenix', label: 'Mountain Standard Time (MST)' },
    { value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
    { value: 'America/Anchorage', label: 'Alaska Time (AKT)' },
    { value: 'America/Adak', label: 'Hawaii-Aleutian Time (HAT)' },
    { value: 'Pacific/Honolulu', label: 'Hawaii Standard Time (HST)' }
]


export const daysOfWeek = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
]

export const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


export const times = [
    {
        "value": "00:00",
        "label": "12:00 AM"
    },
    {
        "value": "00:15",
        "label": "12:15 AM"
    },
    {
        "value": "00:30",
        "label": "12:30 AM"
    },
    {
        "value": "00:45",
        "label": "12:45 AM"
    },
    {
        "value": "01:00",
        "label": "1:00 AM"
    },
    {
        "value": "01:15",
        "label": "1:15 AM"
    },
    {
        "value": "01:30",
        "label": "1:30 AM"
    },
    {
        "value": "01:45",
        "label": "1:45 AM"
    },
    {
        "value": "02:00",
        "label": "2:00 AM"
    },
    {
        "value": "02:15",
        "label": "2:15 AM"
    },
    {
        "value": "02:30",
        "label": "2:30 AM"
    },
    {
        "value": "02:45",
        "label": "2:45 AM"
    },
    {
        "value": "03:00",
        "label": "3:00 AM"
    },
    {
        "value": "03:15",
        "label": "3:15 AM"
    },
    {
        "value": "03:30",
        "label": "3:30 AM"
    },
    {
        "value": "03:45",
        "label": "3:45 AM"
    },
    {
        "value": "04:00",
        "label": "4:00 AM"
    },
    {
        "value": "04:15",
        "label": "4:15 AM"
    },
    {
        "value": "04:30",
        "label": "4:30 AM"
    },
    {
        "value": "04:45",
        "label": "4:45 AM"
    },
    {
        "value": "05:00",
        "label": "5:00 AM"
    },
    {
        "value": "05:15",
        "label": "5:15 AM"
    },
    {
        "value": "05:30",
        "label": "5:30 AM"
    },
    {
        "value": "05:45",
        "label": "5:45 AM"
    },
    {
        "value": "06:00",
        "label": "6:00 AM"
    },
    {
        "value": "06:15",
        "label": "6:15 AM"
    },
    {
        "value": "06:30",
        "label": "6:30 AM"
    },
    {
        "value": "06:45",
        "label": "6:45 AM"
    },
    {
        "value": "07:00",
        "label": "7:00 AM"
    },
    {
        "value": "07:15",
        "label": "7:15 AM"
    },
    {
        "value": "07:30",
        "label": "7:30 AM"
    },
    {
        "value": "07:45",
        "label": "7:45 AM"
    },
    {
        "value": "08:00",
        "label": "8:00 AM"
    },
    {
        "value": "08:15",
        "label": "8:15 AM"
    },
    {
        "value": "08:30",
        "label": "8:30 AM"
    },
    {
        "value": "08:45",
        "label": "8:45 AM"
    },
    {
        "value": "09:00",
        "label": "9:00 AM"
    },
    {
        "value": "09:15",
        "label": "9:15 AM"
    },
    {
        "value": "09:30",
        "label": "9:30 AM"
    },
    {
        "value": "09:45",
        "label": "9:45 AM"
    },
    {
        "value": "10:00",
        "label": "10:00 AM"
    },
    {
        "value": "10:15",
        "label": "10:15 AM"
    },
    {
        "value": "10:30",
        "label": "10:30 AM"
    },
    {
        "value": "10:45",
        "label": "10:45 AM"
    },
    {
        "value": "11:00",
        "label": "11:00 AM"
    },
    {
        "value": "11:15",
        "label": "11:15 AM"
    },
    {
        "value": "11:30",
        "label": "11:30 AM"
    },
    {
        "value": "11:45",
        "label": "11:45 AM"
    },
    {
        "value": "12:00",
        "label": "12:00 PM"
    },
    {
        "value": "12:15",
        "label": "12:15 PM"
    },
    {
        "value": "12:30",
        "label": "12:30 PM"
    },
    {
        "value": "12:45",
        "label": "12:45 PM"
    },
    {
        "value": "13:00",
        "label": "1:00 PM"
    },
    {
        "value": "13:15",
        "label": "1:15 PM"
    },
    {
        "value": "13:30",
        "label": "1:30 PM"
    },
    {
        "value": "13:45",
        "label": "1:45 PM"
    },
    {
        "value": "14:00",
        "label": "2:00 PM"
    },
    {
        "value": "14:15",
        "label": "2:15 PM"
    },
    {
        "value": "14:30",
        "label": "2:30 PM"
    },
    {
        "value": "14:45",
        "label": "2:45 PM"
    },
    {
        "value": "15:00",
        "label": "3:00 PM"
    },
    {
        "value": "15:15",
        "label": "3:15 PM"
    },
    {
        "value": "15:30",
        "label": "3:30 PM"
    },
    {
        "value": "15:45",
        "label": "3:45 PM"
    },
    {
        "value": "16:00",
        "label": "4:00 PM"
    },
    {
        "value": "16:15",
        "label": "4:15 PM"
    },
    {
        "value": "16:30",
        "label": "4:30 PM"
    },
    {
        "value": "16:45",
        "label": "4:45 PM"
    },
    {
        "value": "17:00",
        "label": "5:00 PM"
    },
    {
        "value": "17:15",
        "label": "5:15 PM"
    },
    {
        "value": "17:30",
        "label": "5:30 PM"
    },
    {
        "value": "17:45",
        "label": "5:45 PM"
    },
    {
        "value": "18:00",
        "label": "6:00 PM"
    },
    {
        "value": "18:15",
        "label": "6:15 PM"
    },
    {
        "value": "18:30",
        "label": "6:30 PM"
    },
    {
        "value": "18:45",
        "label": "6:45 PM"
    },
    {
        "value": "19:00",
        "label": "7:00 PM"
    },
    {
        "value": "19:15",
        "label": "7:15 PM"
    },
    {
        "value": "19:30",
        "label": "7:30 PM"
    },
    {
        "value": "19:45",
        "label": "7:45 PM"
    },
    {
        "value": "20:00",
        "label": "8:00 PM"
    },
    {
        "value": "20:15",
        "label": "8:15 PM"
    },
    {
        "value": "20:30",
        "label": "8:30 PM"
    },
    {
        "value": "20:45",
        "label": "8:45 PM"
    },
    {
        "value": "21:00",
        "label": "9:00 PM"
    },
    {
        "value": "21:15",
        "label": "9:15 PM"
    },
    {
        "value": "21:30",
        "label": "9:30 PM"
    },
    {
        "value": "21:45",
        "label": "9:45 PM"
    },
    {
        "value": "22:00",
        "label": "10:00 PM"
    },
    {
        "value": "22:15",
        "label": "10:15 PM"
    },
    {
        "value": "22:30",
        "label": "10:30 PM"
    },
    {
        "value": "22:45",
        "label": "10:45 PM"
    },
    {
        "value": "23:00",
        "label": "11:00 PM"
    },
    {
        "value": "23:15",
        "label": "11:15 PM"
    },
    {
        "value": "23:30",
        "label": "11:30 PM"
    },
    {
        "value": "23:59",
        "label": "11:59 PM"
    }
];

export const openingHours = {
    monday: {
        twenyfour: false,
        closed: false,
        hour: [{ id: 0, open: '12:00', close: '00:00' }]
    },
    tuesday: {
        twenyfour: false,
        closed: false,
        hour: [{ id: 0, open: '12:00', close: '00:00' }]
    },
    wednesday: {
        twenyfour: false,
        closed: false,
        hour: [{ id: 0, open: '12:00', close: '00:00' }]
    },
    thursday: {
        twenyfour: false,
        closed: false,
        hour: [{ id: 0, open: '12:00', close: '00:00' }]
    },
    friday: {
        twenyfour: false,
        closed: false,
        hour: [{ id: 0, open: '12:00', close: '00:00' }]
    },
    saturday: {
        twenyfour: false,
        closed: false,
        hour: [{ id: 0, open: '12:00', close: '00:00' }]
    },
    sunday: {
        twenyfour: false,
        closed: false,
        hour: [{ id: 0, open: '12:00', close: '00:00' }]
    }
}
