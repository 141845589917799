import React, { useState } from 'react'
import { Animate, Button, Card, Checkbox, TextInput, ModalContainer, useView, Icon } from 'components/lib'
import './style.scss'

export function PlanOption({ plans, loading, setPlans, mutation }) {
    const view = useView()
    const [modal, setModal] = useState({ open: false, data: {} })

    const handleSave = async () => {
        try {
            const res = await mutation.mutateAsync()
            view.notification.show(res.data.message, 'success', true)
        } catch (e) {
            view.notification.show(e.response.data.message, 'error', true)
        }
    }


    const handleFeatureCheck = (checked, plan, feature, type) => {
        const updatedPlans = [...plans].map(p => {
            if (p === plan) {
                const updatedFeatures = p.features.map(f => {
                    if (f === feature) {
                        if (typeof f.checked === 'boolean') {
                            // For features with only a single checked value
                            return { ...f, checked };
                        } else {
                            // For features with separate 'custom' and 'standard' checked values
                            return {
                                ...f,
                                checked: {
                                    ...f.checked,
                                    [type]: checked
                                }
                            };
                        }
                    }
                    return f;
                });
                return { ...p, features: updatedFeatures };
            }
            return p;
        });

        setPlans(updatedPlans);
    }

    const handleFeatureChange = (value, plan, feature, name) => {
        const updatedPlans = [...plans].map(p => {
            if (p.id === plan.id) {
                const updatedFeatures = p.features.map(f => {
                    if (f.key === feature.key) {
                        return { ...f, [name]: ['name', 'description'].includes(name) ? value : parseInt(value || 0) };
                    }
                    return f;
                });

                return { ...p, features: updatedFeatures };
            }
            return p;
        });

        setPlans(updatedPlans)
    }

    const handlePlanProp = (name, value, plan) => {
        const updatedPlans = [...plans].map(p => {
            if (p === plan) {
                return { ...p, [name]: typeof value === 'string' ? value : parseInt(value || 0) };
            }
            return p;
        });

        setPlans(updatedPlans);
    }

    const renderFeature = (p, f, i) => (
        <div key={i} className={`flex justify-evenly gap-x-4 h-[3.125rem] px-2 items-center  flex-grow ${i !== p.features.length - 1 ? 'border-b' : ''}`}>
            {
                typeof f.checked === 'boolean'
                    ? <Checkbox
                        checked={f.checked}
                        onChange={(_, checked) => handleFeatureCheck(checked, p, f)}
                    />
                    : typeof f.value === 'number'
                        ? <div className='w-full h-[3.125rem] px-2'>
                            <TextInput
                                value={f.value}
                                onChange={(_, value) => handleFeatureChange(value, p, f, 'value')}
                                className='border-0 outline-0 text-center'
                            />
                        </div>
                        : <>
                            <Checkbox
                                checked={f.checked?.custom}
                                onChange={(_, checked) => handleFeatureCheck(checked, p, f, 'custom')}
                            />

                        </>
            }
            <div className='flex gap-4'>
                <button onClick={() => setModal({ open: true, data: { p, f } })} className='text-sm w-fit text-green-500'>Edit?</button>
                <button onClick={() => removeFeature(p, f)} className='text-sm w-fit'>
                    <Icon image='trash' color='red' />
                </button>
            </div>
        </div>
    )

    const removeFeature = (plan, feature) => {
        view.modal.show({
            title: 'Remove Feature',
            buttonText: 'Remove',
            buttonColor: 'red',
            onlyActions: true,
            text: 'Are you sure you want to delete your account? After You Save You will lose all of your data and this can not be undone.',
            takeAction: () => {
                const updatedPlans = [...plans].map(p => {
                    if (p === plan) {
                        const updatedFeatures = p.features.filter(f => f !== feature);
                        return { ...p, features: updatedFeatures };
                    }
                    return p;
                });

                setPlans(updatedPlans);
            }
        })
    }

    const addFeature = (plan) => {
        const updatedPlans = [...plans].map(p => {
            if (p === plan) {
                const updatedFeatures = [...p.features, { key: Math.random().toString(36).substring(7), name: '', value: 0, checked: false }];
                return { ...p, features: updatedFeatures };
            }
            return p;
        });

        setPlans(updatedPlans);
    }

    return (
        <div className='plan_options'>
            {
                modal.open && (
                    <ModalContainer close={() => setModal({ open: false, data: {} })} >
                        <Card title='Edit?' className='flex flex-col'>
                            <TextInput
                                type='textarea'
                                name='description'
                                label={`Edit ? for ${modal.data.f.name} in ${modal.data.p.name} plan`}
                                value={plans.find(p => p.id === modal.data.p.id).features.find(f => f.key === modal.data.f.key).description}
                                onChange={(_, value) => {
                                    handleFeatureChange(value, modal.data.p, modal.data.f, 'description')
                                }}
                            />
                            <p className='text-sm'>You'll need to hit the save button to save changes</p>
                            <Button
                                text='Close'
                                color='red'
                                action={() => setModal({ open: false, data: {} })}
                                small
                            />
                        </Card>
                    </ModalContainer>
                )
            }
            <Animate loading={loading}>
                <Card className='lg:max-w-5xl'>
                    <div className='w-fit mb-5'>
                        <div className="flex border overflow-x-auto">
                            <div className="flex flex-col">
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>Plan</span>
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>Price ID</span>
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>Yearly Price ID</span>
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>Pricing</span>
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>Yearly Pricing</span>
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>Yearly OFF %</span>
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>One-Time Setup Fee</span>
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>Yearly Setup Fee OFF %</span>
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>Trial Days (0 = no trial)</span>
                                <span className='border-b border-t h-[3.125rem] px-2 flex items-center'>New Campaign Discount</span>
                                {
                                    plans.map((p, i) => (
                                        i === 0 && <>
                                            {
                                                p.features.slice(0, 1).map((f, j) => <div key={j} className={`${j !== p.features.length - 2 ? 'border-b' : ''} h-[3.125rem] px-2 flex items-center  min-w-[15rem]`}>
                                                    <TextInput
                                                        value={f.name}
                                                        onChange={(_, value) => handleFeatureChange(value, p, f, 'name')}
                                                        name='name'
                                                        className='border-0 outline-0'
                                                        containerClassName='w-full'
                                                    />
                                                </div>)
                                            }
                                            {
                                                p.features.slice(1, p.features.length).map((f, j) => <span key={j} className={`${j !== p.features.length - 2 ? 'border-b' : ''} h-[3.125rem] px-2 flex items-center min-w-[15rem]`}>
                                                    <TextInput
                                                        value={f.name}
                                                        onChange={(_, value) => handleFeatureChange(value, p, f, 'name')}
                                                        name='name'
                                                        className='border-0 outline-0'
                                                        containerClassName='w-full'
                                                    />
                                                </span>)
                                            }
                                        </>
                                    ))
                                }
                            </div>
                            <div className='flex'>
                                {
                                    plans.map((p, i) => (
                                        <div key={i} className="flex flex-col border-l">
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.name}
                                                    name='name'
                                                    onChange={(name, value) => handlePlanProp(name, value, p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.id}
                                                    name='id'
                                                    onChange={(name, value) => handlePlanProp(name, value, p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.y_id}
                                                    name='y_id'
                                                    onChange={(name, value) => handlePlanProp(name, value, p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.price}
                                                    name='price'
                                                    onChange={(name, value) => handlePlanProp(name, parseInt(value), p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.y_price}
                                                    name='y_price'
                                                    onChange={(name, value) => handlePlanProp(name, parseInt(value), p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.y_off}
                                                    name='y_off'
                                                    onChange={(name, value) => handlePlanProp(name, parseInt(value), p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.setup_fee}
                                                    name='setup_fee'
                                                    onChange={(name, value) => handlePlanProp(name, parseInt(value), p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.y_setupfee_off}
                                                    name='y_setupfee_off'
                                                    onChange={(name, value) => handlePlanProp(name, parseInt(value), p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.trial}
                                                    name='trial'
                                                    onChange={(name, value) => handlePlanProp(name, parseInt(value), p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            <span className='border-b h-[3.125rem] px-2'>
                                                <TextInput
                                                    value={p.all_services_coupon}
                                                    name='all_services_coupon'
                                                    onChange={(name, value) => handlePlanProp(name, value, p)}
                                                    className='border-0 outline-0 text-center'
                                                />
                                            </span>
                                            {
                                                p.features.slice(0, 1).map((f, i) => renderFeature(p, f, i))
                                            }
                                            {
                                                p.features.slice(1, p.features.length).map((f, i) => renderFeature(p, f, i))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <button
                            className='mt-2 py-1 px-3 text-sm bg-sky-400 hover:bg-sky-500 text-white rounded-md'
                            onClick={() => addFeature(plans[0])}
                        >
                            Add Feature
                        </button>
                    </div>

                    <p className='text-sm mb-2'>Do not save only if you are sure the changes is correct, any update will not be affected unless you hit the Save button.</p>
                    <Button action={handleSave} loading={mutation.isLoading} className='w-full' text='Save' />
                </Card>
            </Animate>
        </div>
    )
}